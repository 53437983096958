import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Heading,
  Image,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
} from '@chakra-ui/react';

import { TEXT_PROPS, DEFAULT_PROPS } from '@/constants/default-props';
import Bank from '@invitato/helpers/dist/constants/banks';
import txtWording from './locales';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" fontFamily="serif" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="secondaryColorText" marginTop="0" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            size="sm"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="btnPrimaryColor"
            variant="outline"
            marginTop="16px"
            fontFamily="body"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Modal size="full" onClose={onClose} isOpen={visible}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent maxWidth="500px">
        <ModalCloseButton marginTop="12px" {...DEFAULT_PROPS.button_transparent} />
        <ModalBody>
          <Heading mt="30%" {...TEXT_PROPS.title} color="secondaryColorText">
            Bank Transfer
          </Heading>
          <Text {...TEXT_PROPS.body} color="secondaryColorText">
            {txtWording.modalDesc[lang]}
          </Text>
          {renderBank(Bank.bca, 'Daniel Hadi', '7615090450')}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;
