import React, { useState } from 'react';
import { string } from 'prop-types';
import { Box, Text, Heading, Center, Button, useToast, Image } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import ModalBank from './ModalBank';
import ModalAddress from './ModalAddress';
import ModalConfirmation from './ModalConfirmation';

import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import txtWording from './locales';

import {
  ENABLE_SEND_GIFT,
  ENABLE_BANK_TRANSFER,
  ENABLE_GIFT_CONFIRMATION,
  DEFAULT_LANGUAGE,
} from '@/constants/feature-flags';
import { DEFAULT_PROPS } from '@/constants/colors';
import { TEXT_PROPS } from '@/constants/default-props';
import { IMG_LOGO_WEDDING } from '@/constants/assets';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  idle: '',
  confirm: 'confirmation',
};

const DEFAULT_BUTTON = {
  size: 'sm',
  fontWeight: 'normal',
  color: 'btnMainColor',
  bgColor: 'bgSecondary',
  // textTransform: 'uppercase',
  // letterSpacing: '2px',
  ...DEFAULT_PROPS.button,
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ lang, ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: message || txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Ooops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box bgColor="bgPrimary">
      <Box {...rest}>
        <Box
          padding="42px 32px"
          bgRepeat="no-repeat"
          width="100%"
          bgSize="100% 100%"
          bgColor="bgPrimary"
          borderRadius="16px"
        >
          <Center>
            <WithAnimation>
              <Heading {...TEXT_PROPS.title}>{txtWording.title[lang]}</Heading>
            </WithAnimation>
          </Center>
          <WithAnimation>
            <Text {...TEXT_PROPS.body} m="16px 0 32px 0">
              {txtWording.desc[lang]}
            </Text>
          </WithAnimation>
          {ENABLE_BANK_TRANSFER && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button {...DEFAULT_BUTTON} onClick={() => setModalState(MODAL_STATE.bank)}>
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
          )}
          {/* Send gift button section */}
          {ENABLE_SEND_GIFT && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button {...DEFAULT_BUTTON} onClick={() => setModalState(MODAL_STATE.address)}>
                  {txtWording.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_GIFT_CONFIRMATION && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button {...DEFAULT_BUTTON} onClick={() => setModalState(MODAL_STATE.confirm)}>
                  {txtWording.confirm[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
        </Box>
      </Box>
      <WithAnimation type="zoom">
        <Image w="120px" src={IMG_LOGO_WEDDING} margin="0 auto" mt="16px" />
      </WithAnimation>
      <ModalBank
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.bank}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.address}
        onCopyText={onCopyText}
      />
      <ModalConfirmation
        lang={lang}
        isOpen={modalState === MODAL_STATE.confirm}
        onClose={onClose}
      />
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
};

GiftCardSection.defaultProps = {
  lang: DEFAULT_LANGUAGE,
};

export default React.memo(GiftCardSection);
